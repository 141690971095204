@use "sass:color";
::selection {
 background-color: mix($white, $orange, 65%);
 color: $black;
}


#dashboardBlocks {
 $max: 3;
 $color: $orange;
 display: grid;
 column-gap: 1rem;
 grid-template-columns: repeat($max, 1fr);
 .contentBlock {
  position: relative;
  padding: .7rem;
  @for $item from 1 through $max {
   &:nth-child(#{$item}) {
    $complement: color.adjust($color, $lightness: $item*9%);
    background-color: $complement;
    color: color-yiq($complement);
   }
  }
  .title {
   text-align: center;
   font-size: 2rem;
   line-height: $line-height-base;
   text-transform: uppercase;
   letter-spacing: -2px;
   word-spacing: 5px;
   font-weight: $font-weight-bold;
   font-family: $font-family-monospace;
   margin-bottom: .75rem;
  }
  .cardWrapper {
   display: grid;
   align-items: flex-start;
   column-gap: .75rem;
   row-gap: .75rem;
   grid-template-columns: repeat(2, 1fr);
   grid-template-rows: repeat(3,1fr);
  }
  .card {
   transition: transform 0.35s ease-in-out, background-color 0.45s ease-in, color 0.25s ease;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content:center;
   margin-bottom: 0;
   [class*=fa] {
    display: block;
    font-size: 140%;
    line-height: 1rem;
    margin-bottom: .5rem;
    color: rgba($black,.75);
   }
   .fa-mock {
    font-weight: bold;
   }
  }
  .card-body {
   padding: 1rem .75rem;
   text-align: center;
  }
  a.card {
   color: $blue;
   &:hover {
    transform: scale(1.07);
    text-decoration: none;
    background-color: rgba($black,.6);
    &, [class*=fa] {
     color: $white;
    }
   }
  }
 }
 + .row {
  > [class*=col] {
   flex: 1 1 auto;
   max-width: 100%;
   margin-bottom: 0!important;
  }
 }
}

.coloredBoxes {
 $colors: $orange, mix($orange, $red), $green, mix($yellow, $green);
 $max: length($colors);
 .card {
  position: relative;
  overflow: hidden;
  [class*=fa-] {
   bottom: 0px;
   opacity: 0.15;
   position: absolute;
   font-size: 9ch;
   right: 1rem;
  }
 }
 > div {
  @for $item from 1 through $max {
   &:nth-child(#{$max}n + #{$item}) {
    .card {
     $color: nth($colors, $item);
     $complement: adjust-hue($color, -25deg);
     // $mixed: mix($color, $complement);
     color: color-yiq($complement);
     background: $color linear-gradient(155deg, $color, $complement) repeat-x;
     box-shadow: 0 1px 3px rgba($complement, 0.12), 0 1px 2px rgba($color, 0.24);
     transition: all 0.3s cubic-bezier(.25,.8,.25, 1);
     [class*=fa-] {
      color: color-yiq($color);
     }
    }
   }
  }
 }
}