#salesOrder--display {
 // max-width: 1100px;
 label {
  color: #262626;
 }
 [class*=border] {
  border-color: #555!important;
  border-width: 2px!important;
 }
 .form-group {
  display: flex;
  margin-bottom: 0;
 }
 [class*=row] {
  margin-bottom: 1rem;
 }
 label + span {
  display: block;
  flex: 1 1 auto;
 }
 .heading .form-group {
  flex-direction: column;
 }
 .linedBg {
  position: relative;
  line-height: 29px;
  padding: .5rem;
  &:before {
   content: '';
   top: .5rem;
   right: 0;
   left: .5rem;
   bottom: .5rem;
   position: absolute;
   background-image:
   linear-gradient(0deg, transparent 5em, rgba(255,0,0,.2) 0, transparent 5.1em),
   linear-gradient(rgba(0,0,0,.6) 1px, transparent 0);
   background-size: 100% 29px;
  }
 }
}

/* begin print styles! */
@media print {
 body, #mainColumn {
  background-color: #fff;
 }
 #mainColumn > [class*=container] {
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
 }
 #salesOrder--display {
  max-width: 100%;
  font-family: 'arial';
  font-size: 14px;
  line-height: 15px;
  h6 {
   &.text-center {
    text-align: left!important;
   }
  }
  label + span {
   border-bottom: 1px solid #181818;
  }
  .form-group {
   label {
    font-weight: normal;
   }
   span {
    font-weight: $font-weight-bold;
   }
  }
  [class*=border] {
   border-color: #aaa!important;
  }
  [class*=row] {
   margin-bottom: .5rem;
  }
  h1 {
   margin-bottom: .5rem;
   font-size: 24px;
  }
  .img-fluid {
   max-height: 55px;
  }
  .heading, .customerInfo {
   .form-group {
    label {
     order: 2;
    }
    span {
     order: 1;
    }
   }
  }
  .heading {
   .form-group {
    text-align: center;
   }
  }
  .customerInfo {
   .form-row {
    margin-left: 0;
    margin-right: 0;
    > [class*=col] {
     padding-left: 0;
     padding-right: 0;
    }
   }
   .form-group {
    label, span {
     padding: 0 .5rem;
    }
   }
  }
  .carDetails {
   .form-group {
    margin-bottom: .25rem;
   }
  }
  .sellingEtc {
   label + span {
    text-align: right;
   }
  }
 }
 footer {
  display: none;
 }
}

#salesOrder--display {
 .sellingEtc, .financing {
  .form-group {
   > * {
    margin-bottom: .5rem;
    flex: 1 1 auto;
   }
  }
 }
 .customerInfo {
  .form-group {
   flex-direction: column;
  height: 100%;
  }
 }
 .carDetails {
  .form-group {
   display: flex;
   flex-direction: row;
   margin-bottom: .5rem;
   label + span {
    margin-left: .5rem;
    text-align: right;
   }
  }
 }
 .financing {
  .form-group {
   > * {
    &:first-child {
     max-width: 60%;
    }
    &:last-child {
     max-width: 40%;
    }
   }
  }
 }
 .sellingEtc {
  .form-group {
   > * {
    &:first-child {
     max-width: 65%;
    }
    &:last-child {
     max-width: 35%;
    }
   }
  }
 }
}
