// Color system
$white: #fff;
$gray-100: #f7fafc;
// $gray-200: #e9ecef;
// $gray-300: #dee2e6;
$gray-400: #ced4da;
// $gray-500: #adb5bd;
// $gray-600: #7C7C7C;
// $gray-700: #495057;
// $gray-800: #343a40;
$gray-900: #262626;
$black: #000;

$blue: #5380cc;
$indigo: #5957a6;
$purple: #5E2E80;
$pink: #CC49AC;
$red: #cc6353;
$orange: #CC9F53;
$yellow: #FFE24F; //#D9C24E
$green: #8ecc54;
$teal: #68CC92;
$cyan: #5ea6af;

$primary: $blue;
$secondary: $orange;
$success: mix($teal, darken($green, 10%), 15%);
$info: mix($white, $cyan, 20%);
$warning: mix($white, $yellow, 25%);
$danger: mix($yellow, $red, 2%);
// $light: $gray-300;
$dark: $gray-900;

