/*!
FullCalendar Day Grid Plugin v4.2.0
Docs & License: https://fullcalendar.io/
(c) 2019 Adam Shaw
*/
.fc-dayGridDay-view {
	.fc-content-skeleton {
		padding-bottom: 1em;
	}
}
.fc-dayGridWeek-view {
	.fc-content-skeleton {
		padding-bottom: 1em;
	}
}
.fc-dayGrid-view {
	.fc-body {
		.fc-row {
			min-height: 4em;
		}
	}
	.fc-day-number {
		padding: 2px;
	}
	.fc-week-number {
		padding: 2px;
	}
	th.fc-day-number {
		padding: 0 2px;
	}
	th.fc-week-number {
		padding: 0 2px;
	}
	.fc-day-top {
		.fc-week-number {
			min-width: 1.5em;
			text-align: center;
			background-color: #f2f2f2;
			color: grey;
		}
	}
	td.fc-week-number {
		text-align: center;
		>* {
			display: inline-block;
			min-width: 1.25em;
		}
	}
}
.fc-row.fc-rigid {
	overflow: hidden;
	.fc-content-skeleton {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
}
.fc-day-top.fc-other-month {
	opacity: .3;
}
.fc-ltr {
	.fc-dayGrid-view {
		.fc-day-top {
			.fc-day-number {
				float: right;
			}
			.fc-week-number {
				float: left;
				border-radius: 0 0 3px;
			}
		}
	}
}
.fc-rtl {
	.fc-dayGrid-view {
		.fc-day-top {
			.fc-day-number {
				float: left;
			}
			.fc-week-number {
				float: right;
				border-radius: 0 0 0 3px;
			}
		}
	}
}
