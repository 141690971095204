%shadow {
 box-shadow: $box-shadow;
}
.card {
 @extend %shadow;
}
label {
 font-weight: $font-weight-bold;
 // font-size: $font-size-base*.9;
 text-transform: uppercase;
 color: $gray-700;
 &.small, small, .small {
  color: $text-muted;
 }
}

[class*=btn-outline-] {
 border-width: 2px;
}
.btn:not(.btn-link) {
 text-transform: uppercase;
}