#mainColumn {
 background-color: $body-bg;
 box-shadow: 0px 0px 8px -3px rgba($black, .7);
 z-index: 1;
 transition: all .5s ease-in-out;
 > [class*=container] {
  padding-left: $spacer*1.5;
  padding-right: $spacer*1.5;
 }
}
.table-legend {
 margin: 0;
 display: flex;
 padding: 0;
 list-style: none;
 li {
  display: flex;
  align-items: center;
  padding: 0;
  + li {
   margin-left: 1rem;
  }
 }
 [class*=table-] {
  margin-right: .25rem;
  height: 20px;
  width: 20px;
  border: 1px solid rgba(0,0,0,.2);
 }
}