.miniCal {
 .monthHeading {
  padding: $spacer/4 $spacer/2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dropdown {
   margin-right: $spacer*2;
  }
 }
 .daysContainer {
  border: 1px solid rgba($black, .05);
  border-bottom: 0;
  background-color: $white;
 }
 .daysOfWeek {
  // border: 1px solid green;
  background-color: rgba($black, .05);
  > div {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
 }
 .week {
  > div {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   height: 44px;
   border: 1px solid rgba($black, .05);
  }
 }
 .daysOfWeek, .week {
  text-align: center;
  font-size: $font-size-sm;
  display: grid;
  grid-template-columns: repeat(7,1fr);
 }

  // @for $dow from 1 through 7 {
  //  .spans-#{$dow} {
  //   grid-column: 1 / #{$dow};
  //   border: 1px solid red!important;
  //  }
  // }
}