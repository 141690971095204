/*!
FullCalendar Time Grid Plugin v4.2.0
Docs & License: https://fullcalendar.io/
(c) 2019 Adam Shaw
*/
.fc-timeGrid-view {
	.fc-day-grid {
		position: relative;
		z-index: 2;
		.fc-row {
			min-height: 3em;
			.fc-content-skeleton {
				padding-bottom: 1em;
			}
		}
	}
}
.fc {
	.fc-axis {
  text-transform: uppercase;
  font-size: $font-size-sm;
		vertical-align: middle;
		padding: 0 4px;
		white-space: nowrap;
	}
}
.fc-ltr {
	.fc-axis {
		text-align: right;
	}
	.fc-time-grid {
		.fc-event-container {
			margin: 0 2.5% 0 2px;
		}
		.fc-now-indicator-arrow {
			left: 0;
			border-width: 5px 0 5px 6px;
			border-top-color: transparent;
			border-bottom-color: transparent;
		}
	}
}
.fc-rtl {
	.fc-axis {
		text-align: left;
	}
	.fc-time-grid {
		.fc-event-container {
			margin: 0 2px 0 2.5%;
		}
		.fc-now-indicator-arrow {
			right: 0;
			border-width: 5px 6px 5px 0;
			border-top-color: transparent;
			border-bottom-color: transparent;
		}
	}
}
.fc-time-grid {
	position: relative;
	z-index: 1;
	min-height: 100%;
	table {
		border: 0 hidden transparent;
	}
	>.fc-bg {
		z-index: 1;
	}
	.fc-slats {
		position: relative;
		z-index: 2;
		td {
			height: 1.5em;
			border-bottom: 0;
		}
		.fc-minor {
			td {
				border-top-style: dotted;
			}
		}
	}
	>hr {
		position: relative;
		z-index: 2;
	}
	.fc-content-col {
		position: relative;
	}
	.fc-content-skeleton {
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		right: 0;
	}
	.fc-business-container {
		position: relative;
		z-index: 1;
	}
	.fc-bgevent-container {
		position: relative;
		z-index: 2;
	}
	.fc-highlight-container {
		z-index: 3;
		position: relative;
	}
	.fc-event-container {
		position: relative;
		z-index: 4;
	}
	.fc-now-indicator-line {
		z-index: 5;
		border-top-width: 1px;
		left: 0;
		right: 0;
	}
	.fc-mirror-container {
		position: relative;
		z-index: 6;
	}
	.fc-highlight {
		position: absolute;
		left: 0;
		right: 0;
	}
	.fc-bgevent {
		position: absolute;
		z-index: 1;
		left: 0;
		right: 0;
	}
	.fc-event {
		position: absolute;
		z-index: 1;
	}
	.fc-now-indicator-arrow {
		margin-top: -5px;
	}
}
.fc-time-grid-container {
	position: relative;
	z-index: 1;
}
.fc-time-grid-event {
	margin-bottom: 1px;
	.fc-content {
		overflow: hidden;
		max-height: 100%;
	}
	.fc-time {
		padding: 0 1px;
		font-size: .85em;
		white-space: nowrap;
	}
	.fc-title {
		padding: 0 1px;
	}
}
.fc-time-grid-event-inset {
	-webkit-box-shadow: 0 0 0 1px #fff;
	box-shadow: 0 0 0 1px #fff;
}
.fc-time-grid-event.fc-not-start {
	border-top-width: 0;
	padding-top: 1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.fc-time-grid-event.fc-not-end {
	border-bottom-width: 0;
	padding-bottom: 1px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.fc-time-grid-event.fc-short {
	.fc-content {
		white-space: nowrap;
	}
	.fc-time {
		display: inline-block;
		vertical-align: top;
		span {
			display: none;
		}
		&:before {
			content: attr(data-start);
		}
		&:after {
			content: "\000A0-\000A0";
		}
	}
	.fc-title {
		display: inline-block;
		vertical-align: top;
		font-size: .85em;
		padding: 0;
	}
}
.fc-time-grid-event.fc-allow-mouse-resize {
	.fc-resizer {
		left: 0;
		right: 0;
		bottom: 0;
		height: 8px;
		overflow: hidden;
		line-height: 8px;
		font-size: 11px;
		font-family: monospace;
		text-align: center;
		cursor: s-resize;
		&:after {
			content: "=";
		}
	}
}
.fc-time-grid-event.fc-selected {
	.fc-resizer {
		border-radius: 5px;
		border-width: 1px;
		width: 8px;
		height: 8px;
		border-style: solid;
		border-color: inherit;
		background: #fff;
		left: 50%;
		margin-left: -5px;
		bottom: -5px;
	}
}
