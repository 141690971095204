/*!
FullCalendar Bootstrap Plugin v4.2.0
Docs & License: https://fullcalendar.io/
(c) 2019 Adam Shaw
*/
.fc.fc-bootstrap {
	a {
		text-decoration: none;
	}
	a[data-goto] {
		&:hover {
			text-decoration: underline;
		}
	}
}
.fc-bootstrap {
	hr.fc-divider {
		border-color: inherit;
	}
	.fc-today.alert {
		border-radius: 0;
	}
	a.fc-event {
		&:not([href]) {
			&:not([tabindex]) {
				color: $white;
			}
		}
	}
	.fc-popover.card {
		position: absolute;
	}
	.fc-popover {
		.card-body {
			padding: 0;
		}
	}
	.fc-time-grid {
		.fc-slats {
			table {
				background: 0 0;
			}
		}
	}
}
