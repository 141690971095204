/*!
FullCalendar List View Plugin v4.2.0
Docs & License: https://fullcalendar.io/
(c) 2019 Adam Shaw
*/
.fc-event-dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 5px;
}
.fc-rtl {
	.fc-list-view {
		direction: rtl;
	}
	.fc-list-heading-main {
		float: right;
	}
	.fc-list-heading-alt {
		float: left;
	}
	.fc-list-item-marker {
		padding-left: 0;
	}
}
.fc-list-view {
	border-width: 1px;
	border-style: solid;
}
.fc {
	.fc-list-table {
		table-layout: auto;
	}
}
.fc-list-table {
	td {
		border-width: 1px 0 0;
		padding: 8px 14px;
	}
	tr {
		&:first-child {
			td {
				border-top-width: 0;
			}
		}
	}
}
.fc-list-heading {
	border-bottom-width: 1px;
	td {
		font-weight: 700;
	}
}
.fc-ltr {
	.fc-list-heading-main {
		float: left;
	}
	.fc-list-heading-alt {
		float: right;
	}
	.fc-list-item-marker {
		padding-right: 0;
	}
}
.fc-list-item.fc-has-url {
	cursor: pointer;
}
.fc-list-item-marker {
	white-space: nowrap;
	width: 1px;
}
.fc-list-item-time {
	white-space: nowrap;
	width: 1px;
}
.fc-list-item-title {
	a {
		text-decoration: none;
		color: inherit;
	}
	a[href] {
		&:hover {
			text-decoration: underline;
		}
	}
}
.fc-list-empty-wrap2 {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.fc-list-empty-wrap1 {
	width: 100%;
	height: 100%;
	display: table;
}
.fc-list-empty {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}
.fc-unthemed {
	.fc-list-empty {
		background-color: #eee;
	}
}
