#wrapper {
 background-color: mix($white, $gray-600, 82%);
}
@include media-breakpoint-up(lg) {
 body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
 }
 #wrapper {
  flex: 1 1 auto;
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  > div, aside {
   position: relative;
   max-height: 100%;
   overflow-y: auto;
  }
 }
}
@include scrollbars($spacer*.65, $gray-600);