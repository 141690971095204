@import 'bsAddons';
@import 'oneOffs';
@import 'salesOrder';
@import 'mixins/extras';

%fullSpanAbsolution {
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom:0;
}

#main {
 .nav-link {
  display: inline;
 }
 h1:first-of-type {
  small:first-of-type {
   font-size: $h6-font-size;
  }
 }
}
#sidebar {
 @include media-breakpoint-up(md) {
  padding-left: $grid-gutter-width;
  min-width: 30ch;
  max-width: 20vw;
 }
 @include media-breakpoint-up(xl) {
  min-width: 37ch;
  max-width: 27vw;
 }
}
tr[onclick] {
 transition: all .25s ease-in-out;
 &:hover {
  background-color: rgba($orange, .25)!important;
  cursor: pointer;
  text-decoration: none;
  transform: scale(.985);
 }
}

#carImageViewer {
 .nav-link {
  position: relative;
  padding: 0;
  &.active {
   background-color: $black;
   &:before {
    @extend %fullSpanAbsolution;
    content: '';
    background-color: rgba($dark, .8);
   }
   &:after {
    @extend %fullSpanAbsolution;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: $font-size-sm;
    content: 'Viewing'
   }
  }
 }

}

.mockup {
 position: relative;
 &:after {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: $font-size-lg;
  position: absolute;
  font-family: 'Unica One';
 }
 &:hover {
  &:after {
   text-transform: uppercase;
   content: 'Demo Content';
   z-index: 2;
   color: $red;
   background-color: rgba($purple, .9);
   &, * {
    pointer-events: none;
   }
  }
 }
}
table {
 td {
  .btn-group {
   .btn {
    padding: .15rem .35rem;
   }
  }
 }
}

h1, h2, h3 {
 .badge {
  font-size: $font-size-lg;
 }
}
.input-group {
 * {
  border-width: 0;
  // box-shadow: none;
 }
 border-radius: $input-border-radius;
 border-color: $input-border-color;
 border-style: solid;
 border-width: $input-border-width;
 box-shadow: $input-box-shadow;
}
.input-group-text {
 .input-group-append & {
  border-left-width: 0;
 }
}