$yiq-contrasted-threshold: 165;
// Options
$enable-shadows: true;
// $enable-gradients: true;
$enable-responsive-font-sizes: true;

// Components
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow: 0 4px 6px -1px rgba($black, 0.1), 0 2px 4px -1px rgba($black, 0.06);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$component-active-bg: $dark;
$transition-fade: opacity .35s linear;

// $small-font-size: .875rem;


// Body
$body-bg: $gray-100;
$body-color: $gray-900;

// Links
// $link-color: $secondary;

// Typography
$font-family-sans-serif: "Roboto";
// $font-family-monospace: "Unica One";
$font-family-base:  $font-family-sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .82;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.3;
$line-height-lg: $line-height-base;
$line-height-sm: $line-height-base;

$spacer: 1rem !default;

// #ALERTS
$alert-border-radius: 0;

// #BADGES
$badge-font-size: 79%;

// #BREADCRUMBS
$breadcrumb-font-size: $font-size-base * .8;
$breadcrumb-bg: rgba($black, .08);
$breadcrumb-active-color: $dark;

// #BUTTONS
$btn-border-radius: $spacer/4;
$btn-border-radius-lg: $btn-border-radius * 1.2;
$btn-border-radius-sm: $btn-border-radius * .8;
$btn-font-size: $font-size-base;
$btn-font-size-sm: $font-size-sm;
$btn-font-size-lg: $font-size-lg;
$btn-box-shadow: none;
$btn-padding-x: $spacer*1.25; // 1.25rem
$btn-padding-y: $spacer/2; // .5rem

$btn-padding-x-sm: $spacer; // 1rem
$btn-padding-y-sm: $spacer/2; // .5rem

$btn-padding-x-lg: $spacer*1.5; // 1.5rem
$btn-padding-y-lg: $spacer*.75; // .75rem

// #CARDS
$card-border-width: 0;
$card-cap-bg: transparent;

// #CODE
$code-color: $red;

// #DROPDOWNS
$dropdown-min-width: 15rem;
$dropdown-border-radius: 0;
$dropdown-inner-border-radius: $dropdown-border-radius;

// #FORMS
// $input-padding-y: $input-btn-padding-y;
$input-padding-x: .4rem;
$input-placeholder-color: $gray-400;
$label-margin-bottom: .15rem;
$form-feedback-font-size: $font-size-base * .95;
$form-group-margin-bottom: $spacer * 1.3;
$input-group-addon-bg: transparent;

// #GRID
$grid-gutter-width: $spacer * 2;
// #INPUTS
$input-border-radius: 0;
$input-border-radius-lg: $input-border-radius;
$input-border-radius-sm: $input-border-radius;

// #LIST GROUPS
$list-group-border-radius: 0;

// #MODALS
$modal-backdrop-opacity: .75;


// #PROGRESS BARS
$progress-bg: rgba($black, 0.1);
