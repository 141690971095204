
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
 ::-webkit-scrollbar {
  width:  $size;
  height: $size;
 }
 ::-webkit-scrollbar-thumb {background: $foreground-color;}
 ::-webkit-scrollbar-track {
  box-shadow: inset .1rem 0 .1rem rgb(0 0 0 / 5%);
  background: $white;
 }
 // For Internet Explorer
 body {
  scrollbar-face-color: $foreground-color;
  scrollbar-track-color: $background-color;
 }
}

@import 'header';
@import 'mainContent';
@import 'wrapper';
@import 'aside';
.mce-notification-warning {
 display: none!important;
 height: 0!important;
 width: 0!important;
 overflow: hidden!important;
}