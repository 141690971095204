/*!
FullCalendar Core Package v4.2.0 // CUSTOMIZED BY AISHA B. 02/18/2021
Docs & License: https://fullcalendar.io/
(c) 2019 Adam Shaw
*/

%absolution {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}
.fc-button {
 text-transform: uppercase;
	display: inline-block;
	font-weight: 400;
	color: $body-color;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
 padding: $btn-padding-y-sm $btn-padding-x-sm;
 font-size: $btn-font-size-sm;
 line-height: $btn-line-height-sm;
 border-radius: $btn-border-radius-sm;
 transition: $btn-transition;
	&:not(:disabled) {
		cursor: pointer;
	}
	&::-moz-focus-inner {
		padding: 0;
		border-style: none;
	}
	&:hover {
		color: $body-color;
		text-decoration: none;
	}
	&:focus {
		outline: 1px dotted;
		outline: 5px auto -webkit-focus-ring-color;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba($body-color, 0.25);
	}
	&:disabled {
		opacity: .65;
	}
	.fc-icon {
		vertical-align: middle;
	}
}
.fc-event.fc-draggable {
	cursor: pointer;
}
.fc-event[href] {
	cursor: pointer;
}
.fc-popover {
	.fc-header {
		.fc-close {
			cursor: pointer;
			opacity: .65;
			font-size: $close-font-size;
		}
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 2px 4px;
		.fc-title {
			margin: 0 2px;
		}
	}
	position: absolute;
	box-shadow: 0 2px 6px rgba($black,.15);
}
a.fc-more {
	cursor: pointer;
	margin: 1px 3px;
	font-size: .85em;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}
a[data-goto] {
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}
.fc-bg {
	bottom: 0;
 @extend %absolution;
	table {
		height: 100%;
	}
}
.fc-row {
	.fc-bgevent-skeleton {
		bottom: 0;
		z-index: 2;
		table {
			height: 100%;
		}
		td {
			border-color: transparent;
		}
	}
	.fc-highlight-skeleton {
		bottom: 0;
		z-index: 3;
		table {
			height: 100%;
		}
		td {
			border-color: transparent;
		}
	}
	table {
		border-left: 0 hidden transparent;
		border-right: 0 hidden transparent;
		border-bottom: 0 hidden transparent;
	}
	&:first-child {
		table {
			border-top: 0 hidden transparent;
		}
	}
	position: relative;
	.fc-bg {
		z-index: 1;
	}
	.fc-content-skeleton {
		position: relative;
		z-index: 4;
		padding-bottom: 2px;
		td {
			border-bottom: 0;
		}
		tbody {
			td {
				border-top: 0;
			}
		}
	}
	.fc-mirror-skeleton {
		z-index: 5;
		td {
			border-bottom: 0;
		}
		tbody {
			td {
				border-top: 0;
			}
		}
	}
}
.fc {
	direction: ltr;
	text-align: left;
	table {
		width: 100%;
		box-sizing: border-box;
		table-layout: fixed;
		border-collapse: collapse;
		border-spacing: 0;
		font-size: $font-size-base;
	}
	th {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
		text-align: center;
		border-style: solid;
		border-width: 1px;
		padding: 0;
		vertical-align: top;
	}
	td {
		border-style: solid;
		border-width: 1px;
		padding: 0;
		vertical-align: top;
	}
	td.fc-today {
		border-style: double;
	}
	.fc-row {
		border-style: solid;
		border-width: 0;
		.fc-content-skeleton {
			table {
				background: 0 0;
				border-color: transparent;
			}
			td {
				background: 0 0;
				border-color: transparent;
			}
		}
		.fc-mirror-skeleton {
			td {
				background: 0 0;
				border-color: transparent;
			}
		}
	}
}
.fc-rtl {
	text-align: right;
	.fc-popover {
		.fc-header {
			flex-direction: row-reverse;
		}
	}
	.fc-h-event.fc-not-end {
		margin-left: 0;
		border-left-width: 0;
		padding-left: 1px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.fc-h-event.fc-not-start {
		margin-right: 0;
		border-right-width: 0;
		padding-right: 1px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.fc-h-event {
		.fc-end-resizer {
			cursor: w-resize;
			left: -1px;
		}
		.fc-start-resizer {
			cursor: e-resize;
			right: -1px;
		}
	}
	.fc-h-event.fc-selected {
		.fc-end-resizer {
			margin-left: -4px;
		}
		.fc-start-resizer {
			margin-right: -4px;
		}
	}
	.fc-day-grid-event.fc-allow-mouse-resize {
		.fc-end-resizer {
			margin-left: -2px;
		}
		.fc-start-resizer {
			margin-right: -2px;
		}
	}
}
body {
	.fc {
		font-size: 1em;
	}
}
.fc-highlight {
	background: mix($white, $blue, 65%);
	opacity: .3;
}
.fc-bgevent {
	background: mix($white, $green, 65%);
	opacity: .3;
}
.fc-nonbusiness {
	background: $gray-300;
}
.fc-divider {
	border-style: solid;
	border-width: 1px;
}
hr.fc-divider {
	height: 0;
	margin: 0;
	padding: 0 0 2px;
	border-width: 1px 0;
}
.fc-bgevent-skeleton {
 @extend %absolution;
}
.fc-highlight-skeleton {
 @extend %absolution;
}
.fc-mirror-skeleton {
 @extend %absolution;
	tr {
		&:first-child {
			>td {
				>.fc-day-grid-event {
					margin-top: 0;
				}
			}
		}
	}
}
.fc-scroller {
	-webkit-overflow-scrolling: touch;
	>.fc-day-grid {
		position: relative;
		width: 100%;
	}
	>.fc-time-grid {
		position: relative;
		width: 100%;
	}
}
.fc-event {
	position: relative;
	display: block;
	font-size: $font-size-sm;
	line-height: $line-height-base;
	border-radius: $border-radius;
	// border: 1px solid $blue;
	// background-color: $blue;
	color: $white;
	text-decoration: none;
	&:hover {
		color: $white;
		text-decoration: none;
	}
	.fc-content {
		position: relative;
		z-index: 2;
	}
	.fc-resizer {
		position: absolute;
		z-index: 4;
		display: none;
	}
}
.fc-event-dot {
	background-color: $blue;
}
.fc-not-allowed {
	cursor: not-allowed;
	.fc-event {
		cursor: not-allowed;
	}
}
.fc-event.fc-allow-mouse-resize {
	.fc-resizer {
		display: block;
	}
}
.fc-event.fc-selected {
	.fc-resizer {
		display: block;
		&:before {
			content: "";
			position: absolute;
			z-index: 9999;
			top: 50%;
			left: 50%;
			width: 40px;
			height: 40px;
			margin-left: -20px;
			margin-top: -20px;
		}
	}
	z-index: 9999 !important;
	box-shadow: 0 2px 5px rgba($black, 0.2);
	&:after {
		content: "";
		position: absolute;
		z-index: 1;
		top: -1px;
		right: -1px;
		bottom: -1px;
		left: -1px;
		background: $black;
		opacity: .25;
	}
}
.fc-event.fc-dragging.fc-selected {
	box-shadow: 0 2px 7px rgba($black,.3);
}
.fc-event.fc-dragging {
	&:not(.fc-selected) {
		opacity: .75;
	}
}
.fc-h-event.fc-selected {
	&:before {
		content: "";
		position: absolute;
		z-index: 3;
		top: -10px;
		bottom: -10px;
		left: 0;
		right: 0;
	}
	.fc-resizer {
		border-radius: 4px;
		border-width: 1px;
		width: 6px;
		height: 6px;
		border-style: solid;
		border-color: inherit;
		background: $white;
		top: 50%;
		margin-top: -4px;
	}
}
.fc-ltr {
	.fc-h-event.fc-not-start {
		margin-left: 0;
		border-left-width: 0;
		padding-left: 1px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.fc-h-event.fc-not-end {
		margin-right: 0;
		border-right-width: 0;
		padding-right: 1px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.fc-h-event {
		.fc-start-resizer {
			cursor: w-resize;
			left: -1px;
		}
		.fc-end-resizer {
			cursor: e-resize;
			right: -1px;
		}
	}
	.fc-h-event.fc-selected {
		.fc-start-resizer {
			margin-left: -4px;
		}
		.fc-end-resizer {
			margin-right: -4px;
		}
	}
	.fc-day-grid-event.fc-allow-mouse-resize {
		.fc-start-resizer {
			margin-left: -2px;
		}
		.fc-end-resizer {
			margin-right: -2px;
		}
	}
}
.fc-h-event.fc-allow-mouse-resize {
	.fc-resizer {
		width: 7px;
		top: -1px;
		bottom: -1px;
	}
}
.fc-day-grid-event {
	margin: 0 1px 2px;
	padding: 2px 5px;
	.fc-content {
		white-space: nowrap;
		overflow: hidden;
	}
	.fc-time {
		font-weight: 700;
	}
}
tr {
	&:first-child {
		>td {
			>.fc-day-grid-event {
				margin-top: 2px;
			}
		}
	}
}
.fc-limited {
	display: none;
}
.fc-icon {
 font-family: "Font Awesome 5 Pro";
 font-weight: 900;
 -moz-osx-font-smoothing: grayscale;
 -webkit-font-smoothing: antialiased;
 display: inline-block;
 font-style: normal;
 font-variant: normal;
 text-rendering: auto;
 line-height: 1;
}
.fc-day-grid {
	.fc-row {
		z-index: 1;
	}
}
.fc-more-popover {
	z-index: 2;
	width: 220px;
	.fc-event-container {
		padding: 10px;
	}
}
.fc-now-indicator {
	position: absolute;
	border: 0 solid $red;
}
.fc-unselectable {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}
.fc-unthemed {
	.fc-content {
		border-color: $gray-300;
	}
	.fc-divider {
		border-color: $gray-300;
		background: $gray-200;
	}
	.fc-list-heading {
		td {
			border-color: $gray-300;
			background: $gray-200;
		}
	}
	.fc-list-view {
		border-color: $gray-300;
	}
	.fc-popover {
		border-color: $gray-300;
		background-color: $white;
		border-width: 1px;
		border-style: solid;
		.fc-header {
			background: $gray-200;
		}
	}
	.fc-row {
		border-color: $gray-300;
	}
	tbody {
		border-color: $gray-300;
	}
	td {
		border-color: $gray-300;
	}
	th {
		border-color: $gray-300;
	}
	thead {
		border-color: $gray-300;
	}
	td.fc-today {
		background: $gray-100;
	}
	.fc-disabled-day {
		background: $gray-300;
		opacity: .3;
	}
	.fc-list-item {
		&:hover {
			td {
				background-color: $gray-100;
			}
		}
	}
}
.fc-icon-chevron-left {
	&:before {
		content: "\f053";
	}
}
.fc-icon-chevron-right {
	&:before {
		content: "\f054";
	}
}
.fc-icon-chevrons-left {
	&:before {
		content: "\f323";
	}
}
.fc-icon-chevrons-right {
	&:before {
		content: "\f324";
	}
}
.fc-icon-minus-square {
	&:before {
		content: "\f146";
	}
}
.fc-icon-plus-square {
	&:before {
		content: "\f0fe";
	}
}
.fc-icon-x {
	&:before {
		content: "\f00d";
	}
}
.fc-button-primary {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
	&:hover {
		color: $white;
		background-color: darken($secondary, 10%);
		border-color: darken($secondary, 10%);
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba($secondary, 0.5);
	}
	&:disabled {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}
	&:not(:disabled) {
		&:active {
			color: $white;
			background-color: darken($yellow, 15%);
			border-color: darken($yellow, 15%);
			&:focus {
				box-shadow: 0 0 0 0.2rem rgba($yellow, 0.5);
			}
		}
	}
	&:not(:disabled).fc-button-active {
		color: $component-active-color;
		background-color: $component-active-bg;
		border-color: $component-active-bg;
		&:focus {
			box-shadow: 0 0 0 0.2rem rgba($component-active-bg, 0.5);
		}
	}
}
.fc-button-group {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	> .fc-button {
		position: relative;
		flex: 1 1 auto;
		&:hover, &:focus, &:active {
			z-index: 1;
		}
		&:not(:first-child) {
			margin-left: -1px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		&:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	>.fc-button.fc-button-active {
		z-index: 1;
	}
}
.fc-toolbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
 flex-wrap: wrap;
 .fc-left {
  order: 2;
  flex: 0 0 50%;
  max-width: 50%;
  .fc-button-group {
   margin-right: $spacer;
  }
 }
 .fc-center {
  order: 1;
  flex: 0 0 100%;
  max-width: 100%;
 }
 .fc-right {
  order: 3;
  flex: 0 0 50%;
  text-align: right;
  max-width: 50%;
 }
	> * {
		> {
			// &:not(:first-child) {
			// 	margin-left: .75em;
			// }
		}
	}
	h2 {
		font-size: $h2-font-size;
		margin: 0;
	}
}
.fc-toolbar.fc-header-toolbar {
	margin-bottom: $spacer*1.5;
}
.fc-toolbar.fc-footer-toolbar {
	margin-top: $spacer*1.5;
}
.fc-view-container {
	position: relative;
	* {
  &, &:before, &:after {
			box-sizing: content-box;
		}
	}
}
.fc-view {
	position: relative;
	z-index: 1;
	>table {
		position: relative;
		z-index: 1;
	}
}
@media print {
	.fc-bg,
 .fc-bgevent-container,
 .fc-bgevent-skeleton,
 .fc-business-container,
 .fc-highlight-container,
 .fc-highlight-skeleton,
 .fc-mirror-container,
 .fc-mirror-skeleton,
 .fc-popover,
 .fc-button-group {
		display: none;
	}
	.fc-more,
 .fc-more-cell,
 .fc-slats {
		display: none !important;
	}
	.fc-event {
		.fc-resizer {
			display: none;
		}
		background: $white !important;
		color: $black !important;
		page-break-inside: avoid;
	}
	.fc {
		max-width: 100% !important;
		hr,
  td,
  th,
  thead, tbody {
			border-color: $gray-400 !important;
			background: $white !important;
		}
		tbody {
			.fc-row {
				min-height: 0 !important;
				height: auto !important;
				.fc-content-skeleton {
					position: static;
					padding-bottom: 0 !important;
					tbody {
						tr {
							&:last-child {
								td {
									padding-bottom: 1em;
								}
							}
						}
					}
					table {
						height: 1em;
					}
				}
			}
		}
		tr.fc-limited {
			display: table-row !important;
		}
		td.fc-limited {
			display: table-cell !important;
		}
		button {
			display: none;
		}
	}
	.fc-time-grid {
		min-height: 0 !important;
		.fc-event.fc-not-end {
			&:after {
				content: "...";
			}
			border-bottom-width: 1px !important;
		}
		.fc-event.fc-not-start {
			&:before {
				content: "...";
			}
			border-top-width: 1px !important;
		}
		hr {
			display: none !important;
		}
		.fc-event {
			.fc-time {
				span {
					display: none;
				}
				white-space: normal !important;
				&:after {
					content: attr(data-full);
				}
			}
			position: static !important;
			margin: 3px 2px !important;
		}
		.fc-content-skeleton {
			position: static;
			table {
				height: 4em;
			}
		}
		.fc-event-container {
			margin: 0 !important;
		}
	}
	.fc-row {
		border-color: $gray-400 !important;
		background: $white !important;
		border: 0 !important;
		margin: 0 !important;
	}
	.fc-timeGrid-view {
		.fc-axis {
			display: none;
		}
	}
	.fc-day-grid-container,
 .fc-scroller,
 .fc-time-grid-container {
		overflow: visible !important;
		height: auto !important;
	}
}