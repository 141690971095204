#header {
 background-color: $black;
 position: relative;
 z-index: 2;
 font-size: max(1.5 * (1vw + 1vh) / 1.8, 15px);
 > [class*=container] {
  padding: 0;
 }
  .btn-group {
   margin-bottom: .5rem;
  }
 .branding {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  justify-content: space-between;
  .navbar-brand {
   padding: 0;
   margin: 0;
  }
  img {
   max-height: 116px;
  }
 }
 .dropdown {
  &-toggle {
   &[aria-expanded=true] {
    &::after {
     opacity: 1;
    }
   }
   &[aria-expanded=false] {
    &::after {
     opacity: .5;
    }
   }
   &::after {
    vertical-align: -5px;
    margin-bottom: -6px;
    margin-left: 0px;
    font-weight: 300;
    color: var(--orange);
    content: "\f149";
    font-family: "Font Awesome 5 Pro";
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
   }
  } // .dropdown-toggle
  &-menu {
   &:not(.megamenu) {
    max-width: 35ch;
   }
   border:0;
   max-height: 80vh;
   overflow-y: auto;
   font-size: $font-size-sm;
   background-color: $white;
   .list-group-item {
    white-space: normal;
    padding: $spacer/2 $spacer;
    &:hover {
     background-color: rgba($black, .1);
    }
    &:not(:active) {
     background-color: transparent;
    }
   }
  }
 }
 .nav-link, .logoutBtn .btn-link {
  color: $white;
  text-transform: uppercase;
 } // .nav-link
 .logoutBtn {
  .btn-link {
   font-size: inherit;
   border: 0;
   text-decoration: none!important;
   color: $orange;
  }
 }
 @include media-breakpoint-only(md) {
  .navbar {
   display: grid;
   grid-template-rows: max-content;
   grid-template-columns: auto repeat(2, 1fr);
   grid-template-areas:
   "logo menu1 menu1"
   "logo menu2 menu2"
   ;
  }
  .branding {
   grid-area: logo;
  }
  .multiNav {
   align-items: flex-end;
   justify-content: flex-end;
  }
  #leftNav {
   grid-area: menu1;
   display: flex;
   flex-direction: column;
  }
  #rightNav {
   grid-area: menu2;
  }
  .megamenu {
   max-width: 100vw!important;
   left: -85vw;
  }
 }
 @include media-breakpoint-up(sm) {}
 @include media-breakpoint-up(lg) {
  .branding {
   img {
    max-width: 100%;
    max-height: 80px;
   }
  }
  .navbar {
   .branding, #leftNav, #rightNav {
    flex: 1 1 auto;
   }
   #leftNav, #rightNav {
    .navbar-nav {
     border-top: solid 2px rgba($gray-600,.3);
     flex: 1 1 auto;
     width: 100%;
    }
   }
   .branding {
    max-width: 20%;
    text-align: center;
    order: 2;
    position: relative;
    z-index: 10;
    img {
     filter: drop-shadow(0px 0px 5px rgba($black, .85));
    }
   }
   #leftNav {
    order: 1;
   }
   #rightNav {
    justify-content: flex-end;
    order: 3;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    .navbar-nav {
     justify-content: flex-end;
     order: 2;
    }
    .logoutBtn {
     order: 1;
    }
   }
  }
  .nav-link {
   padding-right: .75rem;
   padding-left: .75rem;
  }
  .logoutBtn {
   .btn-link {
    position: relative;
    text-decoration: none!important;
    text-transform: uppercase;
    letter-spacing: 0;
    font-size: $h4-font-size;
    font-weight: $font-weight-bold;
    font-family: "Unica One";
    color: $orange;
    font-weight: 500;
    &:before {
     content: "\f2f5";
     font-family: "Font Awesome 5 Pro";
     font-weight: 900;
     opacity: .5;
     font-size: $h5-font-size;
    }
   }
  }
 }
 @include media-breakpoint-up(xl) {
  .branding {
   margin-bottom: -3vw;
   margin-top: -.75rem;
   img {
    max-height: 167px;
   }
  }
  .nav-link, .logoutBtn .btn-link {
   padding-left: 1.25vw;
   padding-right: 1.25vw;
  }
 }
}
// header > container > navbar > branding > navbar-brand > img
// header > container > navbar > branding > navbar-toggler
.multiNav {} // #leftNav & #rightNav
// .multiNav > navbar-nav > nav-item > nav-link
// .multiNav > navbar-nav > nav-item > dropdown-menu
// .multiNav > navbar-nav > nav-item > list-group-flush
// .multiNav > navbar-nav > nav-item > dropdown-menu > [class*=item]
// .multiNav > navbar-nav > nav-item > nav-link
// .multiNav > navbar-nav > nav-item > nav-link
// .multiNav > navbar-nav > nav-item > nav-link
#leftNav {}
// #leftNav > btn-group
#rightNav {}

.megamenu {
 padding-top: 3rem!important;
 z-index: 3;
 width: 130ch;
 left: -40.75ch;
 max-width: unset;
 min-width: unset;
 /*padding: .75rem;
 column-gap: .75rem;*/
 grid-template-columns: repeat(3, 1fr);
 grid-template-areas:
  'searcher searcher searcher'
  'sales customer accounting'
  'sales inventory service'
  'sales website admin'
 ;
 &.show {
  display: grid;
 }
 .searchBox {
  grid-area: searcher;
 }
 .subItem {
  padding: .75rem 1rem;
  &[data-grid-area=sales] {grid-area: sales;}
  &[data-grid-area=customer] {grid-area: customer;}
  &[data-grid-area=inventory] {grid-area: inventory;}
  &[data-grid-area=website] {grid-area: website;}
  &[data-grid-area=accounting] {grid-area: accounting;}
  &[data-grid-area=service] {grid-area: service;}
  &[data-grid-area=admin] {grid-area: admin;}
 }
 .dropdown-header {
  color: $secondary;
  font-weight: 700;
  font-size: 1.15rem;
  padding: 0 .5rem;
  text-transform: uppercase;
 }
 .list-group-item {
  border-width: 0 0 1px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &:hover {
   background-color: rgba($black, .15);
  }
  &:last-child {
   border-width: 0;
  }
 }
}