aside {
 $size: 33ch;
 &#userColumn, &#tempColumn {
  min-width: $size;
  max-width: $size;
 }
 &#userColumn.closed {
  margin-left: -#{$size};
 }
 &#tempColumn.closed {
  margin-right: -#{$size};
 }
 @include media-breakpoint-down(lg) {
  &#userColumn {
   margin-left: -#{$size};
  }
  &#userColumn.closed {
   margin-left: 0;
  }
 }
}
#userColumn, #tempColumn {
 transition: all .5s ease-in-out;
 .list-group-item {
  background-color: transparent;
 }
}